import { connect } from 'react-redux';
import Component from './component';

import {
    add,
} from 'Redux/modules/admin/events/actions';

import {
    list as listProducts,
} from 'Redux/modules/admin/products/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            add: dispatch(add),
            listProducts: dispatch(listProducts),
        },
    }),
)(Component);