import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFERS, ADMIN_USER_OFFERS_MANAGE, ADMIN_EVENTS_ADD } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import AddForm from 'Components/admin/events/AddForm';
import { TAB_USER_OFFERS } from 'Components/pages/admin/UserOffers/component';
import { TAB_EVENTS_LIST } from 'Components/pages/admin/UserOffersManage/component';

export default class AdminEventsAdd extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-events-add"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_USER_OFFERS.path),
                        label: 'Zamówienia',
                    }, {
                        to: withVariables(ADMIN_USER_OFFERS.path, {}, { tab: TAB_USER_OFFERS }),
                        label: 'Lista zamówień',
                    }, {
                        to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: queryObject.userOfferId }, { tab: TAB_EVENTS_LIST }),
                        label: 'Edytuj zamówienie',
                    }, {
                        to: withVariables(ADMIN_EVENTS_ADD.path),
                        label: 'Nowe wydarzenie',
                    }]}
                >
                    <ViewHeader
                        title="Nowe wydarzenie"
                    />
                    <AddForm
                        location={location}
                        history={history}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}